/* Global */

const global = {
  // Maximum content width when resolution is large
  maxContentWidth: 1156,
  // Width of pickupp logo
  logoWidth: 110,
  // Height of appBar in different views
  appBarHeight: {
    desktop: '6rem',
    mobile: '3.5rem',
  },
}

/* Breakpoints */

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: global.maxContentWidth,
    xl: 1920,
  },
}

/* Typography */

const fontSize = 16

const typography = {
  fontSize,
  htmlFontSize: fontSize,
}

/* Utils */

const isLightColor = (color) => {
  return [
    'white',
    'pink',
    'fadeGrey',
  ].includes(color)
}

const utils = {
  isLightColor,
}

export default {
  global,
  breakpoints,
  typography,
  utils,
}
