/* eslint-disable react/prop-types */

import { ApolloProvider } from '@apollo/react-hooks'
import { createMuiTheme, makeStyles, ThemeProvider } from '@pickupp/ui/styles'
import React, { useContext, useEffect, useState } from 'react'

import { BROWSER } from '~/config'
import Chat from '~/src/components/Chat'
import ErrorBoundary from '~/src/components/ErrorBoundary'
import SEO from '~/src/components/SEO'
import {
  FeatureToggleContextProvider,
  GeolocationContextProvider,
  WebsocketContextProvider,
} from '~/src/contextProviders'
import {
  AnimationContext,
  LayoutContext,
  LocaleContext,
  PostContext,
  SettingContext,
} from '~/src/contexts'
import styles from '~/src/globalStyles'
import muitheme from '~/src/muitheme'
import { client } from '~/src/services/apollo'

const theme = createMuiTheme(muitheme)
const useStyles = makeStyles(styles)

const App = ({ children }) => {
  const [isClient, setClient] = useState(false)

  // Use styles here to ensure global styles can receive theme
  useStyles()

  // Force render app to fix hydration issue
  // https://github.com/gatsbyjs/gatsby/issues/14601#issuecomment-499922794
  useEffect(() => {
    setClient(true)
  }, [])
  const key = isClient ? 'client' : 'server'

  // Provide animation context to enable or disable animation on the app level
  const [animationEnabled, setAnimationEnabled] = useState(BROWSER)
  const animationContext = {
    animationEnabled,
    enableAnimation: () => setAnimationEnabled(true),
    disableAnimation: () => setAnimationEnabled(false),
  }

  const layoutContext = useContext(LayoutContext)

  return (
    <AnimationContext.Provider value={animationContext}>
      {layoutContext && (
        <SEO {...layoutContext.seo} />
      )}
      <ErrorBoundary key={key}>
        {children}
      </ErrorBoundary>
    </AnimationContext.Provider>
  )
}

const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props
  const {
    layoutContext,
    localeContext,
    settingContext,
    postContext,
  } = pageContext

  return (
    <GeolocationContextProvider>
      <ThemeProvider theme={theme}>
        <LayoutContext.Provider value={layoutContext}>
          <LocaleContext.Provider value={localeContext}>
            <SettingContext.Provider value={settingContext}>
              <PostContext.Provider value={postContext}>
                <WebsocketContextProvider.Provider>
                  <FeatureToggleContextProvider.Provider>
                    <App>{element}</App>
                    <Chat />
                  </FeatureToggleContextProvider.Provider>
                </WebsocketContextProvider.Provider>
              </PostContext.Provider>
            </SettingContext.Provider>
          </LocaleContext.Provider>
        </LayoutContext.Provider>
      </ThemeProvider>
    </GeolocationContextProvider>
  )
}

const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      {element}
    </ApolloProvider>
  )
}

export {
  wrapPageElement,
  wrapRootElement,
}
