import _ from 'underscore'

/**
 * @template T
 * @param {T} obj
 * @param {string[]} prefix
 */

export const flattenObj = (obj, prefix = []) => {
  return Object.assign({}, ...Object.entries(obj).map(([key, value]) => {
    if (value instanceof Object) {
      return flattenObj(value, prefix.concat(key))
    }
    return { [prefix.concat(key).join('.')]: value }
  }))
}

/**
 * @template T
 * @param {string[]} targetKeys
 * @returns { (md: Metadata, req: T, retry?: number) => string }
 */
export const generateCacheKey = (...targetKeys) => (...pars) => {
  const metadata = pars[0].getMap instanceof Function ? { ...pars[0].getMap() } : { ...pars[0] }
  const metadataKey = Object.keys(metadata).filter((k) => (targetKeys.length > 0 ? targetKeys.includes(k) : k.includes('x-pickupp-')))
  const cachedMeta = _.pick(metadata, ...metadataKey)
  const cachedObj = flattenObj({
    md: cachedMeta,
    req: pars[1],
    retry: pars[2],
  })
  return JSON.stringify(cachedObj, Object.keys(cachedObj).sort())
}
