import moment from 'moment'
import { useEffect as ReactUseEffect, useState as ReactUseState } from 'react'

import { generateCacheKey } from '../../utils/cache'

/**
 * @typedef FeatureToggle
 * @property {string} id
 * @property {string} key
 * @property {string} start_from
 * @property {string} stop_from
 * @property {string} updated_by
 * @property {string} updated_at
 * @property {string} created_at
 * @property {string} tag
 */

/**
 * @param {Object} o
 * @param {string} o.useRequest
 * @param {() => Promise<import('axios').AxiosResponse<FeatureToggle[]>>} o.getFeatureToggles
 * @param {import('../../utils/env').Region} o.region
 * @param {import('../../utils/env').Env} o.env
 * @param {boolean} o.isSocketReady
 * @param {() => {}} o.clearOnMessageHandlers
 * @param {() => {}} o.addOnMessageHandler
 * @returns {{ isEnabled: (key: string) => boolean}}
 */

export const useFeatureToggleStore = ({ region, env, getFeatureToggles, useRequest, clearOnMessageHandlers, addOnMessageHandler, isSocketReady, customReactHooks = {} }) => {
  const {
    useState = ReactUseState,
    useEffect = ReactUseEffect,
  } = customReactHooks

  const [localCopy, setLocalCopy] = useState({})
  const [isReady, setIsReady] = useState(false)
  /** @type { { request: () => Promise<import('axios').AxiosResponse<FeatureToggle[]>>, data: FeatureToggle[]}} */
  const {
    request: fetchFeatureToggles,
    data: featureData,
  } = useRequest(getFeatureToggles, {
    initialData: [],
    transformData: ({ features }) => features,
  })

  /**
   * @param {string} key
   * @returns {boolean}
   */

  const isEnabled = (key) => {
    const cachedKey = generateCacheKey('region', 'env')({ region, env }, { key })
    if (!(cachedKey in localCopy)) return false
    /** @type {FeatureToggle} */
    const feature = localCopy[cachedKey]
    if (!feature) return false
    if (moment(feature.start_from).isBefore(moment()) && (!feature.stop_from || moment(feature.stop_from).isAfter(moment()))) {
      return true
    }
    return false
  }

  useEffect(() => {
    return () => {
      clearOnMessageHandlers()
    }
  }, [])

  useEffect(() => {
    if (isSocketReady) {
      fetchFeatureToggles({ tag: 'developing' })
      addOnMessageHandler(`${region}.${env}.setting.FeatureToggles.CreateFeature`, () => fetchFeatureToggles({ tag: 'developing' }))
      addOnMessageHandler(`${region}.${env}.setting.FeatureToggles.UpdateFeature`, () => fetchFeatureToggles({ tag: 'developing' }))
      addOnMessageHandler(`${region}.${env}.setting.FeatureToggles.DeleteFeature`, () => fetchFeatureToggles({ tag: 'developing' }))
    }
  }, [isSocketReady])

  useEffect(() => {
    const newLocalCopy = featureData.reduce((acc, cur) => {
      acc[generateCacheKey('region', 'env')({ env, region }, { key: cur.key })] = cur
      return acc
    }, localCopy)
    setLocalCopy((prev) => ({
      ...prev,
      ...newLocalCopy,
    }))
  }, [featureData])

  useEffect(() => {
    if (Object.keys(localCopy).length > 0) setIsReady(true)
  }, [localCopy])

  return { isEnabled, isReady }
}
