import { useEffect } from 'react'

/**
 * @typedef {{
 *   baseUrl: string;
 *   websiteToken: string;
 *   chatwootSettings?: object;
 * }} ChatwootWidgetProps
 */

/**
 * ```
 * import { ChatwootWidget } from '@pickupp/ui/core'
 * ```
 *
 * @param {ChatwootWidgetProps} props
 */

const ChatwootWidget = ({
  baseUrl,
  websiteToken,
  chatwootSettings = {},
}) => {
  useEffect(() => {
    if (!baseUrl || !websiteToken) return

    window.chatwootSettings = {
      hideMessageBubble: false,
      position: 'right',
      locale: 'en',
      ...chatwootSettings,
    };
    (function (d, t) {
      const g = d.createElement(t)
      const s = d.getElementsByTagName(t)[0]
      g.src = `${baseUrl}/packs/js/sdk.js`
      g.defer = true
      g.async = true
      s.parentNode.insertBefore(g, s)
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken,
          baseUrl,
        })
      }
    }(document, 'script'))
  }, [
    baseUrl,
    websiteToken,
    chatwootSettings,
  ])

  return null
}

export default ChatwootWidget
