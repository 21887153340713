export default (theme) => ({
  mainContainer: ({
    color,
    weight,
    uppercase,
    disableLineBreak,
    strikethrough,
  }) => ({
    color: theme.palette.common[color] || 'inherit',
    fontWeight: weight,
    textTransform: uppercase ? 'uppercase' : null,
    whiteSpace: disableLineBreak ? 'inherit' : 'pre-line',
    textDecoration: strikethrough ? 'line-through' : null,
  }),
})
