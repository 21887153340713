import { useEffect as ReactUseEffect, useState as ReactUseState } from 'react'

const useDebounce = (value, delay = 250, customReactHooks = {}) => {
  const {
    useEffect = ReactUseEffect,
    useState = ReactUseState,
  } = customReactHooks

  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
