import { ChatwootWidget } from '@pickupp/ui/src/core'
import React, { useContext } from 'react'

import { BROWSER, CHATWOOT_TOKEN, CHATWOOT_URL } from '~/config'
import { LocaleContext } from '~/src/contexts'
import { useChatwoot } from '~/src/hooks/chatwoot'

const Chat = () => {
  const localeContext = useContext(LocaleContext)
  const { locale } = useContext(LocaleContext)

  useChatwoot({ locale })

  return BROWSER && localeContext && !window.disableChatwoot ? (
    <ChatwootWidget
      baseUrl={CHATWOOT_URL}
      websiteToken={CHATWOOT_TOKEN}
    />
  ) : null
}

export default Chat
