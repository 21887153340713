import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import _ from 'underscore'

import { LANGUAGES, REGION, SITE_URL } from '~/config'
import { LocaleContext } from '~/src/contexts'
import { useSettings } from '~/src/hooks'

import { getStructuredData } from './StructuredData'

const SEO = ({
  meta,
  title,
  description,
  ogTitle,
  ogDescription,
  ogImage,
  structuredData,
}) => {
  const { og_image: defaultOgImage } = useSettings()
  const { originalPath, language: currentLang } = useContext(LocaleContext)

  const defaultLange = REGION === 'TW' ? 'zh' : 'en'

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `)

  const metaDescription = description || site.siteMetadata.description
  const openGraphTitle = ogTitle || title
  const openGraphDescription = ogDescription || metaDescription
  const openGraphImage = ogImage || (defaultOgImage && defaultOgImage[0] ? defaultOgImage[0].src : '')
  const pageUrl = _.compact([SITE_URL, '/', currentLang || defaultLange, originalPath]).join('')

  return (
    <Helmet
      htmlAttributes={{
        lang: currentLang,
      }}
      title={title}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: openGraphTitle,
        },
        {
          property: 'og:description',
          content: openGraphDescription,
        },
        {
          property: 'og:image',
          content: openGraphImage,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: pageUrl,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link rel="canonical" href={pageUrl} />
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`${SITE_URL}/${defaultLange}${originalPath}`}
      />
      {LANGUAGES.map((language) => (
        <link
          key={language.key}
          rel="alternate"
          hrefLang={language.key}
          href={`${SITE_URL}/${language.key}${originalPath}`}
        />
      ))}
      {
        structuredData && (
          <script type="application/ld+json">
            {
              getStructuredData(structuredData.type, {
                ...structuredData.props,
                url: pageUrl,
              })
            }
          </script>
        )
      }
    </Helmet>
  )
}

SEO.defaultProps = {
  meta: [],
  description: '',
  ogTitle: '',
  ogDescription: '',
  ogImage: null,
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogImage: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
