import { useContext as ReactUseContext } from 'react'

import { WebsocketContext } from './context'

export const useWebsocket = ({ customReactHooks = {} }) => {
  const {
    useContext = ReactUseContext,
  } = customReactHooks

  return useContext(WebsocketContext)
}
