import MuiIcon from '@material-ui/core/Icon'
import { useTheme } from '@material-ui/core/styles'
import React from 'react'

import styles from '../styles'

/**
 * @typedef {{
 *   name: string;
 *   className?: string;
 *   color?: 'primary' | 'secondary' | 'white' | 'textDisabled' | 'textPrimary' | 'darkGrey' | 'lightGrey' | 'yellow' | 'curiousBlue' | 'green' | 'pink' | 'darkOrange' | 'paleGrey' | 'navyBlue';
 *   size?: string;
 *   fixedSize?: boolean;
 * }} MaterialIconProps
 */

/**
 * @param {MaterialIconProps} props
 */
const MaterialIcon = ({
  name,
  className,
  color,
  size = '1.5rem',
  fixedSize = true,
}) => {
  // TODO: use inline style for performance seek, need to migrate to other style adapter in future
  const theme = useTheme()
  const style = styles(theme).icon({ color, size, fixedSize })

  return (
    <MuiIcon
      style={style}
      className={className}
    >
      {name}
    </MuiIcon>
  )
}

export default MaterialIcon
