// Only use for feature toggle
import { useCallback, useEffect, useState } from 'react'

/**
 * @param api
 * @param options
 */
export default function useRequest(
  api,
  options,
) {
  const { initialData = null, transformData = (d) => d } = options

  const [payload, setPayload] = useState({})
  const [response, setResponse] = useState()
  const [data, setData] = useState(initialData)
  const [loading, setLoading] = useState(false)
  // To make sure the first render doesn't fire the request
  const [initial, setInitial] = useState(true)
  // To make sure request is always fired, even with same payload
  const [flag, setFlag] = useState(true)

  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      if (initial || loading) return
      setLoading(true)
      try {
        const res = await api(payload)
        if (didCancel) return
        setResponse(res)
        setData(transformData(res.data.data))
      } catch (e) {
        console.log('Error', e)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    return () => {
      didCancel = true
    }
  }, [payload, initial, flag])

  const request = useCallback((p) => {
    setPayload(p)
    setInitial(false)
    setFlag((f) => !f)
  }, [])

  return { response, data, loading, request }
}
