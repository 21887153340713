import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import merge from 'merge-anything'

import defaultTheme from '../muitheme'

export default (customTheme = {}) => {
  // Merge custom theme into default theme
  const theme = merge(defaultTheme, customTheme)

  const muitheme = createMuiTheme(theme)

  return responsiveFontSizes(muitheme)
}
