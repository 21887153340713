import React from 'react'

const LayoutContext = React.createContext()
const LocaleContext = React.createContext()
const SettingContext = React.createContext()
const PostContext = React.createContext()
const BlockContext = React.createContext()
const AnimationContext = React.createContext()
const GeolocationContext = React.createContext()

export {
  AnimationContext,
  BlockContext,
  GeolocationContext,
  LayoutContext,
  LocaleContext,
  PostContext,
  SettingContext,
}
