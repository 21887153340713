const constants = {
  LANGUAGES: [
    { key: 'en', label: 'English', short_label: 'EN' },
    { key: 'ms', label: 'Melayu', short_label: 'MS', hide: true },
  ],
  LOCALE_LOCAL_STORAGE: 'pickupp-my-landing-locale',
  FB_PIXEL_ID: '1144934845630116',
  BING_ADS_ID: null,
  PHONE_PATTERN: '^[0][0-9]{8,10}$',
  PICKUPP_INFO: {
    logo: 'https://assets.pickupp.io/strapi/pickupp_red_8baee5ea92.png',
    phone: '',
    address: {
      street_address: 'A-1-6, Glomac Damansara, 699 Jalan Damansara',
      address_locality: 'Kuala Lumpur',
      postal_code: '60000',
    },
  },
}

module.exports = {
  local: {
    ...constants,
    S3_BUCKET: 's3://landingv2-dev.pickupp.io',
    S3_REGION: 'ap-southeast-1',
    CLOUDFRONT_ID: 'E2OE9Y19FKYYAY',
    MERCHANT_PORTAL: 'http://portal-dev.my.pickupp.io',
    SITE_URL: 'http://localhost:3000',
    GTM_ID: 'GTM-TM87S9QK',
    SERVER_URL: 'http://localhost/v2',
    SOCKET_URL: 'ws://localhost/websocket',
  },
  development: {
    ...constants,
    S3_BUCKET: 's3://landing-dev.pickupp.io',
    S3_REGION: 'ap-southeast-1',
    CLOUDFRONT_ID: 'EWDEASNWO32EH',
    MERCHANT_PORTAL: 'https://portal-dev.my.pickupp.io',
    SITE_URL: 'https://landingv2-dev.pickupp.io',
    GTM_ID: 'GTM-TM87S9QK',
    SERVER_URL: 'https://gateway-dev.my.pickupp.io/v2',
    SOCKET_URL: 'wss://gateway-dev.my.pickupp.io/websocket',
  },
  uat: {
    ...constants,
    S3_BUCKET: 's3://landingv2-uat.pickupp.io',
    S3_REGION: 'ap-southeast-1',
    CLOUDFRONT_ID: 'E8G4AAEI6PWJY',
    MERCHANT_PORTAL: 'https://portal-dev.hk.pickupp.io',
    SITE_URL: 'https://landing-dev.pickupp.io',
    GTM_ID: 'GTM-NZGW5R47',
    SERVER_URL: 'https://gateway-uat.my.pickupp.io/v2',
    SOCKET_URL: 'wss://gateway-uat.my.pickupp.io/websocket',
  },
  production: {
    ...constants,
    S3_BUCKET: 's3://my.pickupp.io',
    S3_REGION: 'ap-southeast-1',
    CLOUDFRONT_ID: 'E1AKU1T6DHLMGC',
    MERCHANT_PORTAL: 'https://portal.my.pickupp.io',
    SITE_URL: 'https://my.pickupp.io',
    GTM_ID: 'GTM-MRPS5CM',
    SERVER_URL: 'https://gateway.my.pickupp.io/v2',
    SOCKET_URL: 'wss://gateway.my.pickupp.io/websocket',
    CHATWOOT_TOKEN: 'aNzYToErWonTJtnYUwpkKtcD',
  },
}
