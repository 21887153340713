import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import React from 'react'

import styles from '../styles'

const useStyle = makeStyles(styles)

/**
 * @typedef {{
 *   name: string;
 *   className?: string;
 *   color?: 'primary' | 'secondary' | 'white' | 'textDisabled' | 'textPrimary' | 'lightGrey' | 'darkGrey' | 'yellow' | 'curiousBlue' | 'green' | 'pink' | 'darkOrange' | 'paleGrey' | 'navyBlue' | 'bundleBlue' | 'bundleRed' | 'bundleGreen' | 'bundlePurple' | 'bundleBrown' | 'bundlePink' | 'bundleOrange' | 'bundleDarkBlue' | 'bundleDarkRed' | 'bundleTurqoise';
 *   size?: string;
 *   type?: 'fa' | 'fab';
 *   fixedSize?: boolean;
 * }} FontAwesomeIconProps
 */

/**
 * @param {FontAwesomeIconProps} props
 */
const FontAwesomeIcon = ({
  name,
  className,
  color,
  size = '1.5rem',
  type,
  fixedSize = true,
}) => {
  const classes = useStyle({
    size,
    color,
    fixedSize,
  })
  return (
    <i
      className={cx([
        `${type || 'fa'} fa-${name}`,
        className,
        classes.icon,
      ])}
    />
  )
}

export default FontAwesomeIcon
