import { useLocation } from '@reach/router'
import { Link as GatsbyLink } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import { LANGUAGES, SITE_URL } from '~/config'
import { LocaleContext } from '~/src/contexts'
import { trackEvent as bingTrack } from '~/src/services/BingAds'
import { pixelTrack } from '~/src/services/PixelAPI'
import { getTargetUrlWithPageUtmParams } from '~/src/services/utils'

const getLinkTo = ({ toProp, internal, language, linkTarget }) => {
  if (toProp === '#') return toProp
  const url = getTargetUrlWithPageUtmParams(toProp)

  if (!internal) return url

  const localeIncluded = LANGUAGES.some((lang) => url.includes(`/${lang.key}/`))
  const urlWithLocale = localeIncluded ? url : `/${language}${url}`

  // Target '_blank' needs url to be an absolute url
  return linkTarget === '_blank' ? `${SITE_URL}${urlWithLocale}` : urlWithLocale
}

// https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links
const Link = (props) => {
  const location = useLocation()
  const { language } = useContext(LocaleContext)

  const { to: toProp, target, children, event_name, ...otherProps } = props
  const internal = /^\/(?!\/)/.test(toProp)
  const linkTarget = target && target.includes('new_tab') ?
    '_blank' : '_self'
  const to = getLinkTo({ toProp, internal, language, linkTarget })

  const handleClick = async () => {
    if (event_name) {
      bingTrack(event_name)
      await pixelTrack({
        event_name,
        event_source_url: location.href,
      })
    }
  }

  if (!internal) {
    return (
      <a
        href={to}
        target={linkTarget}
        onClick={handleClick}
        {...otherProps}
      >
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink
      to={to}
      target={linkTarget}
      onClick={handleClick}
      {...otherProps}
    >
      {children}
    </GatsbyLink>
  )
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  target: PropTypes.array,
  event_name: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Link.defaultProps = {
  target: null,
  event_name: null,
}

export default Link
