const EVENT_NAME_MAP = {
  LEAD: 'submit_lead_form',
  VIEW_CONTENT: 'page_view',
  VIEWCONTENT: 'page_view',
}

export const trackEvent = (event = '') => {
  try {
    const uetq = window.uetq || []
    const transformedEvent = EVENT_NAME_MAP[event.replace(/\s/g, '').toUpperCase()] || event
    if (!transformedEvent) return
    uetq.push('event', transformedEvent, {})
  } catch (e) {
    console.warn(e)
  }
}
