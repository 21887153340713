import { navigate } from 'gatsby'
import qs from 'qs'

import { LANGUAGES, LOCALE_LOCAL_STORAGE } from '~/config'
import { wrapPageElement, wrapRootElement } from '~/src/root'

const onPreRouteUpdate = ({ location }) => {
  const { pathname, search } = location

  const supportedLanguages = LANGUAGES.map((l) => l.key)

  // Language from url
  const urlLanguage = supportedLanguages.find((l) => {
    return pathname.startsWith(`/${l}/`)
  })

  // Language stored in local storage
  const localLanguage = window.localStorage.getItem(LOCALE_LOCAL_STORAGE)

  // Default language for region
  const defaultLanguage = supportedLanguages[0]

  // Languages in order of preference
  const languages = [
    urlLanguage,
    localLanguage,
    defaultLanguage,
  ]

  // Best language must be supported
  const language = languages.find((l) => supportedLanguages.includes(l))

  // Redirect to localised page if current page is not
  if (!urlLanguage) {
    const path = `/${language}${pathname}${search}`
    navigate(path, { replace: true })
  }

  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  if (params.disableIntercom || params.disableChatwoot) {
    window.disableChatwoot = true
  }
}

const onRouteUpdate = ({ location }) => {
  // Sync local storage language
  const supportedLanguages = LANGUAGES.map((l) => l.key)

  // Language from url
  const urlLanguage = supportedLanguages.find((l) => {
    return location.pathname.startsWith(`/${l}/`)
  })

  if (urlLanguage) {
    window.localStorage.setItem(LOCALE_LOCAL_STORAGE, urlLanguage)
  }
}

export {
  onPreRouteUpdate,
  onRouteUpdate,
  wrapPageElement,
  wrapRootElement,
}
