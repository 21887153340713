import React, { useEffect, useState } from 'react'

import { MODE } from '~/config'
import { GeolocationContext } from '~/src/contexts'
import { responseHeaders } from '~/src/services/utils'

const COUNTRY_KEY = 'cloudfront-viewer-country'

const GeolocationContextProvider = ({ children }) => {
  const [country, setCountry] = useState('')

  const initGeolocation = async () => {
    //* Try to get from local storage.
    let persistedCountry = localStorage.getItem(COUNTRY_KEY)

    if (!persistedCountry) {
      //* Never saved to local storage yet, then save it now.

      const headers = await responseHeaders()
      if (headers) {
        if (MODE === 'production') {
          const clientCountry = headers[COUNTRY_KEY]
          localStorage.setItem(COUNTRY_KEY, clientCountry)
          persistedCountry = clientCountry
        } else {
          //* FOR DEBUG: Please change this value for local simulation.
          persistedCountry = 'MY'
        }
      }
    }

    setCountry(persistedCountry ?? '')
  }

  useEffect(() => {
    initGeolocation()
  }, [])

  const updateCountry = (value) => {
    setCountry(value)
    localStorage.setItem(COUNTRY_KEY, value)
  }

  return (
    <GeolocationContext.Provider value={{ country, updateCountry }}>
      {children}
    </GeolocationContext.Provider>
  )
}

export default GeolocationContextProvider
