import React from 'react'

import { useWebsocket } from '../Websocket/hooks'
import { FeatureToggleContext } from './context'
import { useFeatureToggleStore } from './store'

/**
 * @param {Object} o
 * @param {string} o.useRequest
 * @param {import('react').ReactChild} o.children
 * @param {() => Promise<import('axios').AxiosResponse<import('./store').FeatureToggle>>} o.api
 * @param {import('../../utils/env').Region} o.region
 * @param {import('../../utils/env').Env} o.env
 * @param {Object} [o.customReactHooks] - Different react version might cause crashes, so we can pass some default React hook to override, like useState, useEffect,...
 * @returns {import('react').ReactElement}
 */

export const FeatureToggleProvider = ({ children, useRequest, api: getFeatureToggles, region, env, customReactHooks = {} }) => {
  const { clearOnMessageHandlers, addOnMessageHandler, removeOnMessageHandler, isSocketReady } = useWebsocket({ customReactHooks })
  const store = useFeatureToggleStore({ region, env, getFeatureToggles, useRequest, isSocketReady, clearOnMessageHandlers, addOnMessageHandler, removeOnMessageHandler, customReactHooks })

  return (
    <FeatureToggleContext.Provider value={store}>
      {children}
    </FeatureToggleContext.Provider>
  )
}
