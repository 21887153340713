import { Icon, Typography } from '@pickupp/ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import { useTranslation } from '~/src/hooks'

import Link from '../Link'

const DefaultErrorFallback = () => {
  const t = useTranslation()

  return (
    <div
      style={{
        textAlign: 'center',
        paddingTop: '8rem',
        paddingBottom: '8rem',
        boxSizing: 'border-box',
        backgroundColor: '#ffffff',
      }}
    >
      <Icon
        name="error"
        size="4rem"
        color="primary"
      />
      <Typography html={t('error.maintenance')} />
      <Link to="/">Home</Link>
    </div>
  )
}

const ErrorFallback = ({ error, errorRenderer }) => {
  return errorRenderer ?
    errorRenderer(error.toString()) :
    <DefaultErrorFallback />
}

ErrorFallback.propTypes = {
  error: PropTypes.object.isRequired,
  errorRenderer: PropTypes.func,
}

ErrorFallback.defaultProps = {
  errorRenderer: null,
}

const ErrorBoundary = ({ errorRenderer, children, errorCallback }) => {
  return (
    <ReactErrorBoundary
      onError={errorCallback}
      FallbackComponent={(props) => (
        <ErrorFallback
          errorRenderer={errorRenderer}
          {...props}
        />
      )}
    >
      {children}
    </ReactErrorBoundary>
  )
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  errorRenderer: PropTypes.func,
  errorCallback: PropTypes.func,
}

ErrorBoundary.defaultProps = {
  errorRenderer: null,
  errorCallback: () => null,
}

export default ErrorBoundary
