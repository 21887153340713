import MuiIconButton from '@material-ui/core/IconButton'
import { useTheme } from '@material-ui/core/styles'
import React from 'react'

import FontAwesomeIcon from './FontAwesomeIcon'
import MaterialIcon from './MaterialIcon'
import styles from './styles'

/**
 * @typedef {import('./MaterialIcon').MaterialIconProps} MaterialIconProps
 *
 * @typedef {import('./FontAwesomeIcon').FontAwesomeIconProps} FontAwesomeIconProps
 *
 * @typedef {{ vendor?: 'material'; } & MaterialIconProps} MaterialIconTypeProps
 *
 * @typedef {{ vendor?: 'font_awesome'; } & FontAwesomeIconProps} FontAwesomeIconTypeProps
 *
 * @typedef {MaterialIconTypeProps | FontAwesomeIconTypeProps} IconTypeProps
 *
 * @typedef {IconTypeProps & {
 *   className?: string;
 *   disableRipple?: boolean;
 *   disableHover?: boolean;
 *   disabled?: boolean;
 *   onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
 *   buttonProps?: object;
 * }} IconButtonProps
 *
 * @typedef {IconButtonProps | IconTypeProps} IconProps
 */

/**
 * ```
 * import { Icon } from '@pickupp/ui/core'
 * ```
 *
 * `type` prop is only used if `vendor` is `font_awesome`
 *
 * https://material.io/resources/icons <br>
 * https://fontawesome.com/icons
 */

/**
 * @param {IconTypeProps} props
 */
const IconType = ({
  vendor = 'material',
  ...iconProps
}) => {
  return vendor === 'material' ?
    <MaterialIcon {...iconProps} /> :
    <FontAwesomeIcon {...iconProps} />
}

/**
 * @param {IconButtonProps} props
 */
const IconButton = ({
  className,
  disableRipple = false,
  onClick,
  disabled = false,
  disableHover = false,
  buttonProps = {},
  ...otherProps
}) => {
  // TODO: use inline style for performance seek, need to migrate to other style adapter in future
  const theme = useTheme()
  const style = styles(theme).root({ disableHover })

  return (
    <MuiIconButton
      style={style}
      className={className}
      disableRipple={disableRipple}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
    >
      <IconType
        {...otherProps}
        color={disabled ? 'textDisabled' : otherProps.color}
      />
    </MuiIconButton>
  )
}

/**
 * @param {IconProps} props
 */
const Icon = (props) => {
  return props.onClick ?
    <IconButton {...props} /> :
    <IconType {...props} />
}

export default Icon
