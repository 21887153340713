import 'react-responsive-carousel/lib/styles/carousel.min.css'

export default (theme) => {
  const {
    primary,
    secondary,
    pink,
    white,
    paleGrey,
  } = theme.palette.common

  return {
    '@global': {
      html: {
        fontSize: '16px',
      },

      a: {
        textDecoration: 'none',
      },

      '.carousel .slide': {
        background: 'transparent',
        textAlign: 'unset',
      },

      '.quotation': {
        position: 'relative',
        margin: '2rem 3rem',
      },

      '.quotation::before': {
        position: 'absolute',
        content: 'open-quote',
        fontSize: '8rem',
        color: pink,
        marginLeft: '-4rem',
        marginTop: '-4rem',
      },

      '.quotation::after': {
        position: 'absolute',
        content: 'close-quote',
        fontSize: '8rem',
        bottom: 0,
        right: 0,
        color: pink,
        marginRight: '-4rem',
        marginBottom: '-8rem',
      },

      '.primary-color': {
        color: primary,
      },

      '.secondary-color': {
        color: secondary,
      },
      '.carousel.carousel .slide': {
        background: 'transparent',
      },
      'table.table': {
        width: '100%',
        textAlign: 'center',
        borderSpacing: 0,
        fontWeight: 'bold',
        '& thead': {
          backgroundColor: secondary,
          color: white,
        },
        '& td': {
          border: `1px solid ${paleGrey}`,
          padding: '0.5rem',
        },
      },
    },
  }
}
