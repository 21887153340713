export default (theme) => ({
  iconContainer: {
    width: '1.25em',
  },

  icon: ({
    color,
    size,
    fixedSize,
  }) => {
    const fixedSizeStyle = fixedSize ? {
      // this is override by the && span in root, temporary comment out
      // width: 'calc(18em/14)', // https://github.com/FortAwesome/Font-Awesome/blob/v4.7.0/less/fixed-width.less
      textAlign: 'center',
    } : null
    return {
      ...fixedSizeStyle,
      color: theme.palette.common[color] || 'inherit',
      fontSize: size,
    }
  },
  root: ({ disableHover }) => ({
    padding: theme.spacing(0.5),
    display: 'inline-block',
    // TODO: selector is not working for inline style, to be fixed
    '&:hover': {
      backgroundColor: disableHover ?
        'unset' :
        theme.palette.common.paleGrey,
    },
    '&& span': {
      width: 'auto',
    },
  }),
})
