import { makeStyles } from '@material-ui/core/styles'
import MuiTypography from '@material-ui/core/Typography'
import cx from 'classnames'
import React from 'react'
import sanitizeHtml from 'sanitize-html'

import styles from './styles'

const useStyle = makeStyles(styles)

/**
 * @typedef {import('@material-ui/core/Typography').TypographyProps} MuiTypographyProps
 *
 * @typedef {MuiTypographyProps & {
 *   weight?: number;
 *   uppercase?: boolean;
 *   html?: string;
 *   disableLineBreak?: boolean;
 *   strikethrough?: boolean;
 * }} TypographyProps
 */

const SANITIZE_OPTIONS = {
  allowedAttributes: {
    '*': ['style', 'class'],
    iframe: [
      'src',
      'width',
      'height',
      'frameborder',
      'allow',
      'allowfullscreen',
      'scrolling',
    ],
    th: ['colspan', 'rowspan'],
    td: ['colspan', 'rowspan'],
    a: ['href', 'target'],
    img: ['src'],
  },
  allowedTags: [
    ...sanitizeHtml.defaults.allowedTags,
    'span',
    'img',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
  ],
}

/**
 * ```
 * import { Typography } from '@pickupp/ui/core'
 * ```
 *
 * https://material-ui.com/api/typography/
 */

/**
 * @param {TypographyProps} props
 */
const Typography = (props) => {
  const {
    color,
    weight,
    uppercase = false,
    html,
    disableLineBreak = false,
    className,
    strikethrough,
    typographyStyleOverride,
    ...muiProps
  } = props
  const classes = useStyle({
    color,
    weight,
    uppercase,
    disableLineBreak,
    strikethrough,
  })

  return (
    <MuiTypography
      {...muiProps}
      classes={{
        root: cx(
          classes.mainContainer,
          typographyStyleOverride,
        ),
      }}
      className={className}
      dangerouslySetInnerHTML={html ? {
        __html: sanitizeHtml(html, SANITIZE_OPTIONS),
      } : null}
    />
  )
}

export default Typography
