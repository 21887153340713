import axios from 'axios'
import moment from 'moment'
import qs from 'qs'

import {
  FB_PIXEL_ID,
  LAMBDA_URL,
  MODE,
  PICKUPP_CONVERSIONS_TOKEN,
  REGION,
} from '~/config'

const EVENT_NAME_MAP = {
  LEAD: 'Lead',
  APP_DOWNLOAD: 'AppDownload',
  APPDOWNLOAD: 'AppDownload',
  VIEW_CONTENT: 'ViewContent',
  VIEWCONTENT: 'ViewContent',
}

const generatePixelEventId = (event_name) => (event_name && event_name.length > 0 ? `${event_name}.${moment().unix()}` : moment().unix())

export const pixelTrack = async ({
  event_name: paramEventName = null,
  custom_data = null,
  event_source_url = null,
}) => {
  // if no pixel id, don't do anything
  if (!FB_PIXEL_ID) return
  // remove all whitespace and upper case paramEventName
  // allow for fallback to the paramEventName instead
  const event_name = EVENT_NAME_MAP[paramEventName.replace(/\s/g, '').toUpperCase()] || paramEventName

  // if event name not matched, don't do anything
  if (!event_name) return

  try {
    const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true })

    const region = ['development', 'uat'].includes(MODE.toLowerCase()) ? MODE : REGION

    const data = {
      event_name,
      event_id: generatePixelEventId(event_name),
      custom_data,
      event_source_url,
      action_source: 'website',
      region,
      queryString,
      cookies: document.cookie,
    }

    await axios.post(`${LAMBDA_URL}/track-conversions`, data, {
      headers: {
        'X-Pickupp-Token': PICKUPP_CONVERSIONS_TOKEN,
      },
    })
  } catch (error) {
    console.log('error: ', error)
  }
}
