// https://material-ui.com/customization/default-theme

/* Globals */

const global = {
  stepper: {
    // Size of step label dot
    dotSize: 16,
    // Width of step content line
    lineWidth: 2,
    // To make the step content line connect with
    // the step label dot
    lineVerticalOffset: -5,
  },
}

/* Breakpoints */

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1025,
    xl: 1920,
  },
}

/* Palette */

const colors = {
  primary: '#ef3b3b',
  secondary: '#a41e23',
  darkRed: '#6c201f',
  pink: '#f8aaaa',
  lightPink: '#f7eaeb',
  palePink: '#fdfafa',
  orange: '#f18f01',
  yellow: '#fcba03',
  green: '#009b72',
  blue: '#2196f3',
  black: '#000000',
  grey: '#eee',
  lightBlue: '#5ccff0',
  curiousBlue: '#358fd0',
  navyBlue: '#044b7f',
  darkGreen: '#197278',
  darkGrey: '#414042',
  lightGrey: '#999999',
  paleGrey: '#e2e2e2',
  fadeGrey: '#f5f5f5',
  white: '#ffffff',
  textPrimary: '#666',
  textDisabled: '#acacac',
  red: '#d50000',
  hazeGreenAlpha1: 'rgba(0, 155, 114, 0.1)',

  bundleBlue: '#1187DE',
  bundleRed: '#EF3C3B',
  bundleGreen: '#009B72',
  bundlePurple: '#8A68ED',
  bundleBrown: '#A67E4F',
  bundlePink: '#F64DD1',
  bundleOrange: '#F1AA1F',
  bundleDarkBlue: '#2E65F3',
  bundleDarkRed: '#C53616',
  bundleTurqoise: '#11CBA9',
  slightGrey: '#EAEAEA',
}

const palette = {
  common: colors,
  primary: {
    main: colors.primary,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.secondary,
    contrastText: colors.white,
  },
  error: {
    main: colors.secondary,
    contrastText: colors.white,
  },
  text: {
    primary: colors.darkGrey,
  },
  background: {
    default: colors.white,
  },
}

/* Typography */

const nunito = 'Nunito, serif'
const nunitoSans = 'Nunito Sans, sans-serif'
const fontSize = 14

const typography = {
  fontFamily: nunitoSans,
  fontSize,
  htmlFontSize: fontSize,
  useNextVariants: true,
  color: 'darkGrey',
  h1: {
    fontFamily: nunito,
    fontSize: '4.5rem',
    fontWeight: 700,
    '&:lang(zh)': {
      fontFamily: nunitoSans,
    },
  },
  h2: {
    fontFamily: nunito,
    fontSize: '3.125rem',
    fontWeight: 700,
    '&:lang(zh)': {
      fontFamily: nunitoSans,
    },
  },
  h3: {
    fontFamily: nunito,
    fontSize: '2.625rem',
    fontWeight: 600,
    color: 'red',
    '&:lang(zh)': {
      fontFamily: nunitoSans,
    },
  },
  h4: {
    fontFamily: nunito,
    fontSize: '2.25rem',
    fontWeight: 700,
    '&:lang(zh)': {
      fontFamily: nunitoSans,
    },
  },
  h5: {
    fontFamily: nunito,
    fontSize: '1.5rem',
    fontWeight: 700,
    '&:lang(zh)': {
      fontFamily: nunitoSans,
    },
  },
  h6: {
    fontFamily: nunitoSans,
    fontSize: '1.25rem',
    fontWeight: 700,
  },
  subtitle1: {
    fontFamily: nunitoSans,
    fontSize: '1rem',
    fontWeight: 700,
  },
  subtitle2: {
    fontFamily: nunitoSans,
    fontSize: '0.75rem',
    fontWeight: 700,
  },
  body1: {
    fontFamily: nunitoSans,
    fontSize: '1rem',
    fontWeight: 400,
  },
  body2: {
    fontFamily: nunitoSans,
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  button: {
    fontFamily: nunitoSans,
    fontSize: '1rem',
    fontWeight: 700,
  },
  caption: {
    fontFamily: nunitoSans,
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  overline: {
    fontFamily: nunitoSans,
    fontSize: '1.125rem',
    fontWeight: 800,
  },
}

/* Spacing */

// theme.spacing(1) => '0.5rem'
const spacing = (factor) => {
  return `${0.5 * factor}rem`
}

/* Props */

const props = {
  MuiToolbar: {
    disableGutters: true,
  },

  // MenuItem for Select
  MuiMenuItem: {
    disableRipple: true,
  },
}

/* Overrides */

const overrides = {
  // TextField and Select
  MuiInput: {
    root: {
      fontSize: '1rem',
      padding: 0,
      '&&': {
        marginTop: '0.75rem',
      },
    },
    underline: {
      '&:before, &&&&:hover:before': {
        borderBottom: `1px solid ${colors.paleGrey}`,
      },
    },
    input: {
      padding: 0,
      paddingBottom: '5px',
    },
  },

  // TextField and Select
  MuiInputLabel: {
    formControl: {
      transform: 'translate(0, 18px) scale(1)',
    },
  },

  // MenuItem for Select
  MuiMenuItem: {
    root: {
      fontSize: '0.9rem',
      minHeight: '2.5rem',
    },
  },

  MuiCardContent: {
    root: {
      padding: '1rem',
    },
  },
}

export default {
  global,
  breakpoints,
  palette,
  typography,
  spacing,
  props,
  overrides,
}
