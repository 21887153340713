import axios from 'axios'
import qs from 'qs'
import _ from 'underscore'

import { BROWSER, SITE_URL } from '~/config'

export const canTouch = () => {
  try {
    document.createEvent('TouchEvent')
    return true
  } catch (e) {
    return false
  }
}

export const responseHeaders = async () => {
  try {
    const response = await axios.get(document.location.href)
    return (response.headers)
  } catch (e) {
    return null
  }
}

const POSSIBLE_CHARACTERS = 'abcdefghjklmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789'
export const randomString = (length = 8) => {
  let result = ''
  for (let i = 0; i < length; i++) {
    result += POSSIBLE_CHARACTERS.charAt(Math.floor(Math.random() * 32))
  }
  return result
}

export const convertRemToPixel = (rem) => {
  if (!BROWSER) return null

  const remUnit = typeof rem === 'number' ? rem : Number(rem.replace('rem', ''))
  return remUnit * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export const makeTransitions = ({ duration, type }) => {
  const defaultStyle = {
    transition: `all ${duration}ms ease-in-out`,
  }

  const transitions = {
    slideRightFadeOut: {
      defaultStyle: {
        ...defaultStyle,
        transform: 'translateX(35%)',
        opacity: 0,
      },
      entering: {
        transform: 'translateX(25%)',
        opacity: 0,
      },
      entered: {
        transform: 'translateX(0%)',
        opacity: 1,
      },
      exiting: {
        transform: 'translateX(-25%)',
        opacity: 0,
      },
      exited: {
        transform: 'translateX(-35%)',
        opacity: 0,
      },
    },
    slideLeftFadeOut: {
      defaultStyle: {
        ...defaultStyle,
        transform: 'translateX(-35%)',
        opacity: 0,
      },
      entering: {
        transform: 'translateX(-25%)',
        opacity: 0,
      },
      entered: {
        transform: 'translateX(0%)',
        opacity: 1,
      },
      exiting: {
        transform: 'translateX(25%)',
        opacity: 0,
      },
      exited: {
        transform: 'translateX(35%)',
        opacity: 0,
      },
    },
    fadeIn: {
      defaultStyle: {
        ...defaultStyle,
        opacity: 0,
      },
      entering: {
        opacity: 0,
      },
      entered: {
        opacity: 1,
      },
      exiting: {
        opacity: 0,
      },
      exited: {
        opacity: 0,
      },
    },
  }

  return transitions[type]
}

export const getUtmTrackingProps = (allProps) => {
  if (!allProps || typeof allProps !== 'object') return null

  return Object.keys(allProps).reduce((allUtm, curKey) => {
    if (!curKey?.includes('utm_')) {
      return allUtm
    }

    return {
      ...allUtm,
      [curKey]: allProps[curKey],
    }
  }, {})
}

const getUrlBreakdown = (string) => {
  try {
    // https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
    return new URL(string, SITE_URL) // Add base for relative URL
  } catch {
    return null
  }
}

const checkIsPickuppUrl = (string) => {
  if (!string || typeof string !== 'string') return false
  return string.includes('pickupp.io')
}

const checkIsWithinSameSite = (url) => {
  if (typeof url !== 'string') return false

  return url.includes(SITE_URL)
}

export const getTargetUrlWithPageUtmParams = (targetUrl) => {
  if (!targetUrl || typeof targetUrl !== 'string') return null

  const targetUrlBreakdown = getUrlBreakdown(targetUrl)

  if (!targetUrlBreakdown) return null

  // Only append page url params to pickupp urls
  if (!checkIsPickuppUrl(targetUrlBreakdown.href)) return targetUrl

  const parseOptions = { ignoreQueryPrefix: true }

  // Get page utm params
  const pageUrlQueryParams = BROWSER ?
    qs.parse(window.location.search, parseOptions) : null
  const pageUtmParams = getUtmTrackingProps(pageUrlQueryParams)

  // Append page utm params to target url
  const targetUrlQueryParams = qs.parse(targetUrlBreakdown?.search, parseOptions)

  const stringifiedQueryParams = qs.stringify({
    ...pageUtmParams,
    ...targetUrlQueryParams,
  })

  return _.compact([
    // Exclude origin for relative url to prevent reloading the whole page
    !checkIsWithinSameSite(targetUrlBreakdown.href) && targetUrlBreakdown.origin,
    targetUrlBreakdown.pathname,
    stringifiedQueryParams.length > 0 && `?${stringifiedQueryParams}`,
    targetUrlBreakdown.hash,
  ]).join('')
}
