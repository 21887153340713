import { gql } from 'apollo-boost'
import { useContext, useEffect, useState } from 'react'

import { BROWSER } from '~/config'
import { LocaleContext, SettingContext } from '~/src/contexts'
import theme from '~/src/muitheme'
import { client } from '~/src/services/apollo'

const useSettings = () => {
  const settings = useContext(SettingContext)
  return settings
}

const useTranslation = () => {
  const { translations } = useSettings()
  return (key) => translations[key] || key
}

const useFetch = (request) => {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    (async () => {
      try {
        const res = await request()
        setResponse(res)
      } catch (err) {
        setError(err)
      }
      setLoading(false)
    })()
  }, [])

  return {
    response,
    error,
    loading,
  }
}

const useQuery = ({ type, key, value, fields }) => {
  const { locale } = useContext(LocaleContext)

  const query = gql`
    query {
      all${type}(
        locale: "${locale}",
        where: { ${key}: { eq: ${value} } }
      ) {
        edges {
          node {
            ${fields}
          }
        }
      }
    }
  `
  const { response, error, loading } = useFetch(() => {
    return client.query({ query })
  })

  const data = !error && !loading ?
    response.data[`all${type}`].edges[0].node :
    null

  return {
    data,
    error,
    loading,
  }
}

const useIsMobile = () => {
  const { maxContentWidth } = theme.global
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    if (BROWSER) {
      if (window.innerWidth < maxContentWidth) {
        setMobile(true)
      }

      const handleResize = () => {
        setMobile(window.innerWidth < maxContentWidth)
      }

      window.addEventListener('resize', handleResize)
      // remove event listener on unmount
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    return null
  }, [])

  return mobile
}

const useBrowserSize = () => {
  const { sm, md, lg, xl } = theme.breakpoints.values
  const [windowSize, setWindowSize] = useState('xl')

  useEffect(() => {
    if (BROWSER) {
      const handleResize = () => {
        const w = window.innerWidth
        switch (true) {
        case (w < sm):
          setWindowSize('xs')
          break
        case (w < md):
          setWindowSize('sm')
          break
        case (w < lg):
          setWindowSize('md')
          break
        case (w < xl):
          setWindowSize('lg')
          break
        default:
          setWindowSize('xl')
          break
        }
      }
      handleResize()

      window.addEventListener('resize', handleResize)
      // remove event listener on unmount
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    return null
  }, [])

  return windowSize
}

const useParentSize = ({ ref }) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (ref?.current?.parentNode) {
      setWidth(ref.current.parentNode.clientWidth)
      setHeight(ref.current.parentNode.clientHeight)
    }
  }, [ref])

  return {
    width,
    height,
  }
}

export {
  useBrowserSize,
  useFetch,
  useIsMobile,
  useParentSize,
  useQuery,
  useSettings,
  useTranslation,
}
