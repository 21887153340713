const HK = require('./HK')
const SG = require('./SG')
const MY = require('./MY')
const TW = require('./TW')

const regions = { HK, SG, MY, TW }

const BROWSER = typeof window !== 'undefined'

// Use global variables from webpack for runtime
// Use environment variables for build time
const mode = BROWSER ? __MODE__ : process.env.MODE || process.env.GATSBY_MODE || 'development'
const region = BROWSER ? __REGION__ : process.env.REGION || process.env.GATSBY_REGION || 'HK'
const debug = BROWSER ? __DEBUG__ : !!process.env.DEBUG || false

const constants = {
  MODE: mode,
  REGION: region,
  DEBUG: debug,
  BROWSER,
  GRAPHQL_URL: 'https://strapi.pickupp.io/graphql',
  LAMBDA_URL: 'https://lambda.pickupp.io',
  FORM_URL: 'https://form.pickupp.io',
  PICKUPP_CONVERSIONS_TOKEN: '0182519d0eee95209dbc8811bf1a2ca124dc12d7f08246be23fbfa48422d36a1',
  STRAPI_TOKEN: process.env.GATSBY_STRAPI_TOKEN || '',
  // S3 has a flat structure for files
  // *.html means every html file in every "directory"
  S3_METADATA: {
    '*.html': {
      CacheControl: 'public, max-age=0, must-revalidate',
    },
    'page-data/*': {
      CacheControl: 'public, max-age=0, must-revalidate',
    },
    'static/*': {
      CacheControl: 'public, max-age=31536000, immutable',
    },
    '*.woff2': {
      CacheControl: 'public, max-age=31536000, immutable',
    },
    '*.js': {
      CacheControl: 'public, max-age=31536000, immutable',
    },
    '*.css': {
      CacheControl: 'public, max-age=31536000, immutable',
    },
    'sw.js': {
      CacheControl: 'public, max-age=0, must-revalidate',
    },
  },
  LINKEDIN_ID: '575099',
}

const common = {
  local: {
    ...constants,
    GRAPHQL_URL: 'http://localhost:1337/graphql',
    GOOGLE_SHEETS_URL: 'https://script.google.com/macros/s/AKfycbyOGwTcWC89ueQA1LHaQDTMMH2A7dIXbserTKtXf5pJy9C9Ej0/exec',
    CHATWOOT_URL: 'https://chat.pickupp.io',
    CHATWOOT_TOKEN: 'o4rNhhqQCYTsLTkiRhB2KrDj',
  },
  development: {
    ...constants,
    GRAPHQL_URL: 'https://strapi-dev.pickupp.io/graphql',
    GOOGLE_SHEETS_URL: 'https://script.google.com/macros/s/AKfycbyOGwTcWC89ueQA1LHaQDTMMH2A7dIXbserTKtXf5pJy9C9Ej0/exec',
    CHATWOOT_URL: 'https://chat.pickupp.io',
    CHATWOOT_TOKEN: 'o4rNhhqQCYTsLTkiRhB2KrDj',
  },
  uat: {
    ...constants,
    GRAPHQL_URL: 'https://strapi-dev.pickupp.io/graphql', // temporary using dev, will switch to uat when done migrating the data
    GOOGLE_SHEETS_URL: 'https://script.google.com/macros/s/AKfycbyOGwTcWC89ueQA1LHaQDTMMH2A7dIXbserTKtXf5pJy9C9Ej0/exec',
    CHATWOOT_URL: 'https://chat.pickupp.io',
    CHATWOOT_TOKEN: 'o4rNhhqQCYTsLTkiRhB2KrDj',
  },
  production: {
    ...constants,
    GRAPHQL_URL: 'https://strapi.pickupp.io/graphql',
    GOOGLE_SHEETS_URL: 'https://script.google.com/macros/s/AKfycbx0GDltgmHGzYbR8giz_kgdaknJrsMiesBC_ElZzCMkKWBKBoieXwG9zojmX8ZaSBBG/exec',
    CHATWOOT_URL: 'https://chat.pickupp.io',
  },
}

module.exports = {
  ...regions[region][mode],
  ...common[mode],
}
