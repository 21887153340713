import useDebounce from '@pickupp/ui/src/utils/useDebounce'
import { useEffect, useState } from 'react'
import _ from 'underscore'

import { REGION } from '~/config'

const useAttributes = () => {
  const [args, setArgs] = useState({})

  const debouncedArgs = useDebounce(args, 3000)

  useEffect(() => {
    const contactInfo = _.pick(args, [
      'locale',
    ])
    if (!_.isEmpty(contactInfo)) {
      window.$chatwoot.setCustomAttributes(contactInfo)
    }
  }, [debouncedArgs])

  const setAttributes = (keyValues) => {
    setArgs((old) => _.omit({ ...old, ...keyValues }, _.isUndefined))
  }

  return {
    setAttributes,
  }
}

const useReady = () => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const onReady = () => setReady(true)

    window.addEventListener('chatwoot:ready', onReady)
    return () => {
      window.removeEventListener('chatwoot:ready', onReady)
    }
  }, [])

  useEffect(() => {
    if (!ready) return
    window.$chatwoot.toggleBubbleVisibility('show')
  }, [ready])

  return {
    ready,
  }
}

const useChatwoot = ({ locale }) => {
  const { ready } = useReady()
  const { setAttributes } = useAttributes()

  useEffect(() => {
    if (!ready) return
    setAttributes({
      locale,
      region: REGION.toLowerCase(),
      country_code: REGION.toUpperCase(),
      city: REGION.toUpperCase(),
    })
  }, [ready])
}

export {
  useChatwoot,
}
