import React from 'react'

import { WebsocketContext } from './context'
import { useWebSocketStore } from './store'

const useStoreData = (useObserver, useStores) => {
  const { sessionStore } = useStores()
  return useObserver(() => ({
    sessionToken: sessionStore.sessionToken,
  }))
}

/**
 * @param {Object} o
 * @param {import('react').ReactChild} o.children
 * @param {string} o.portal
 * @param {string} o.socketHost
 * @param {() => {}} o.useObserver
 * @param {() => {}} o.useStores
 * @param {import('../../utils/env').Region} o.region
 * @param {Object} [o.customReactHooks] - Different react version might cause crashes, so we can pass some default React hook to override, like useState, useEffect,...
 * @returns {import('react').ReactElement}
 */

export const WebsocketProvider = ({ children, useStores, useObserver, portal, socketHost, region, customReactHooks = {} }) => {
  const { sessionToken } = useStoreData(useObserver, useStores)
  const store = useWebSocketStore({ isAutoRetry: true, sessionToken, portal, socketHost, region, customReactHooks })

  return (
    <WebsocketContext.Provider value={store}>
      {children}
    </WebsocketContext.Provider>
  )
}
