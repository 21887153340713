const constants = {
  LANGUAGES: [
    { key: 'en', label: 'English', short_label: 'EN' },
  ],
  LOCALE_LOCAL_STORAGE: 'pickupp-sg-landing-locale',
  FB_PIXEL_ID: '402209590544575',
  BING_ADS_ID: null,
  PHONE_PATTERN: '^(\\+65)?( |-)?(3|6|8|9)\\d{7}$',
  PICKUPP_INFO: {
    logo: 'https://assets.pickupp.io/strapi/pickupp_red_8baee5ea92.png',
    phone: '',
    address: {
      street_address: '114 Lavender St, #02-65',
      address_locality: 'Singapore',
      postal_code: '338729',
    },
  },
}

module.exports = {
  local: {
    ...constants,
    S3_BUCKET: 's3://landing-dev.pickupp.io',
    S3_REGION: 'ap-southeast-1',
    CLOUDFRONT_ID: 'EWDEASNWO32EH',
    MERCHANT_PORTAL: 'http://portal-dev.sg.pickupp.io',
    SITE_URL: 'http://localhost:3000',
    GTM_ID: 'GTM-TM87S9QK',
    SERVER_URL: 'http://localhost/v2',
    SOCKET_URL: 'ws://localhost/websocket',
  },
  development: {
    ...constants,
    S3_BUCKET: 's3://landing-dev.pickupp.io',
    S3_REGION: 'ap-southeast-1',
    CLOUDFRONT_ID: 'EWDEASNWO32EH',
    MERCHANT_PORTAL: 'https://portal-dev.sg.pickupp.io',
    SITE_URL: 'https://landingv2-dev.pickupp.io',
    GTM_ID: 'GTM-TM87S9QK',
    SERVER_URL: 'https://gateway-dev.sg.pickupp.io/v2',
    SOCKET_URL: 'wss://gateway-dev.sg.pickupp.io/websocket',
  },
  uat: {
    ...constants,
    S3_BUCKET: 's3://landing-uat.pickupp.io',
    S3_REGION: 'ap-southeast-1',
    CLOUDFRONT_ID: 'E8G4AAEI6PWJY',
    MERCHANT_PORTAL: 'https://portal-dev.hk.pickupp.io',
    SITE_URL: 'https://landing-dev.pickupp.io',
    GTM_ID: 'GTM-NZGW5R47',
    SERVER_URL: 'https://gateway-uat.sg.pickupp.io/v2',
    SOCKET_URL: 'wss://gateway-uat.sg.pickupp.io/websocket',
  },
  production: {
    ...constants,
    S3_BUCKET: 's3://sg.pickupp.io',
    S3_REGION: 'ap-southeast-1',
    CLOUDFRONT_ID: 'E2CSS71F5041DZ',
    MERCHANT_PORTAL: 'https://portal.sg.pickupp.io',
    SITE_URL: 'https://sg.pickupp.io',
    GTM_ID: 'GTM-PTV4F5Q',
    SERVER_URL: 'https://gateway.sg.pickupp.io/v2',
    SOCKET_URL: 'wss://gateway.sg.pickupp.io/websocket',
    CHATWOOT_TOKEN: 'FJS1QS8XS6uyg5qZLjqjiFHC',
  },
}
