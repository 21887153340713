import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import fetch from 'node-fetch'

import { GRAPHQL_URL, STRAPI_TOKEN } from '~/config'

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
  fetch,
})

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: STRAPI_TOKEN ? `Bearer ${STRAPI_TOKEN}` : '',
    },
  }
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [],
    },
  },
})

const cache = new InMemoryCache({ fragmentMatcher })

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

export {
  client,
}
