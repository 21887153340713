import { PICKUPP_INFO, REGION } from '~/config'

/**
 * @typedef {{
 *  url: string;
 *  priceRange: string;
 *  sameAs?: string[];
 * }} LocalBusiness - Each type will have it own properties. Please check https://schema.org/LocalBusiness for all available properties.
 */

/**
 * StructuredData provides the templates of the selected type with customizable properties.
 *
 * @param { "LocalBusiness" } type - Currently, there is only 1 supported type, "LocalBusiness", please add more if needed. Check here for other available types https://schema.org/docs/schemas.html
 * @param { LocalBusiness } props - Properties of the selected type.
 * */

export const getStructuredData = (type, props) => {
  switch (type) {
  case 'LocalBusiness':
  {
    const { url, priceRange, sameAs } = props
    return (JSON.stringify({
      '@context': 'https://schema.org',
      '@type': type,
      name: 'Pickupp',
      image: PICKUPP_INFO.logo,
      '@id': '',
      url,
      telephone: PICKUPP_INFO.phone,
      priceRange: priceRange?.length > 0 ? priceRange : '$$$',
      address: {
        '@type': 'PostalAddress',
        streetAddress: PICKUPP_INFO.address.street_address,
        addressLocality: PICKUPP_INFO.address.address_locality,
        postalCode: PICKUPP_INFO.address.postal_code,
        addressCountry: REGION,
      },
      sameAs,
    }))
  }
  default:
    return ''
  }
}
