const constants = {
  LANGUAGES: [
    { key: 'zh', label: '繁體中文', short_label: '中文' },
  ],
  LOCALE_LOCAL_STORAGE: 'pickupp-tw-landing-locale',
  ADDITIONAL_GTM_ID: 'AW-10823251558',
  FB_PIXEL_ID: '625734545466875',
  BING_ADS_ID: null,
  PHONE_PATTERN: '^(\\+?886|0?)( |-)?(\\d{1})( |-)?(\\d{4})( |-)?(\\d{4})$',
  PICKUPP_INFO: {
    logo: 'https://assets.pickupp.io/strapi/pickupp_red_8baee5ea92.png',
    phone: '',
    address: {
      street_address: 'No. 207, DunHua N Rd, Songshan District',
      address_locality: 'Taipei City',
      postal_code: '105',
    },
  },
}

module.exports = {
  local: {
    ...constants,
    S3_BUCKET: 's3://landingv2-dev.pickupp.io',
    S3_REGION: 'ap-southeast-1',
    CLOUDFRONT_ID: 'EWDEASNWO32EH',
    MERCHANT_PORTAL: 'http://portal-dev.tw.pickupp.io',
    SITE_URL: 'http://localhost:3000',
    GTM_ID: 'GTM-TM87S9QK',
    SERVER_URL: 'http://localhost/v2',
    SOCKET_URL: 'ws://localhost/websocket',
  },
  development: {
    ...constants,
    S3_BUCKET: 's3://landing-dev.pickupp.io',
    S3_REGION: 'ap-southeast-1',
    CLOUDFRONT_ID: 'EWDEASNWO32EH',
    MERCHANT_PORTAL: 'https://portal-dev.tw.pickupp.io',
    SITE_URL: 'https://landingv2-dev.pickupp.io',
    GTM_ID: 'GTM-TM87S9QK',
    SERVER_URL: 'https://gateway-dev.tw.pickupp.io/v2',
    SOCKET_URL: 'wss://gateway-dev.tw.pickupp.io/websocket',
  },
  uat: {
    ...constants,
    S3_BUCKET: 's3://landing-uat.pickupp.io',
    S3_REGION: 'ap-southeast-1',
    CLOUDFRONT_ID: 'E8G4AAEI6PWJY',
    MERCHANT_PORTAL: 'https://portal-dev.hk.pickupp.io',
    SITE_URL: 'https://landing-dev.pickupp.io',
    GTM_ID: 'GTM-NZGW5R47',
    SERVER_URL: 'https://gateway-uat.tw.pickupp.io/v2',
    SOCKET_URL: 'wss://gateway-uat.tw.pickupp.io/websocket',
  },
  production: {
    ...constants,
    S3_BUCKET: 's3://tw.pickupp.io',
    S3_REGION: 'ap-northeast-1',
    CLOUDFRONT_ID: 'E39NARCL17AYHB',
    MERCHANT_PORTAL: 'https://portal.tw.pickupp.io',
    SITE_URL: 'https://tw.pickupp.io',
    GTM_ID: 'GTM-M7SS8NS',
    SERVER_URL: 'https://gateway.tw.pickupp.io/v2',
    SOCKET_URL: 'wss://gateway.tw.pickupp.io/websocket',
    CHATWOOT_TOKEN: 'Jv5sCs6CeYHCyKnNyEVegyr4',
  },
}
