import { WebsocketProvider } from '@pickupp/ui/core'
import React from 'react'

import { REGION, SOCKET_URL } from '~/config'

const Provider = ({ children }) => {
  return (
    <WebsocketProvider
      useStores={() => ({
        sessionStore: {
          sessionToken: null,
        },
      })}
      useObserver={(handler) => handler}
      socketHost={SOCKET_URL}
      region={REGION}
      portal="landing"
    >
      {children}
    </WebsocketProvider>
  )
}

export default { Provider }
