import { FeatureToggleProvider } from '@pickupp/ui/core'
import axios from 'axios'
import React from 'react'

import { REGION, SERVER_URL } from '~/config'
import useRequest from '~/src/hooks/useRequest'

const Provider = ({ children }) => {
  const getFeatureToggle = (request) => axios.get(`${SERVER_URL}/public/feature_toggles?tag=${request.tag}`)

  return (
    <FeatureToggleProvider
      api={getFeatureToggle}
      useRequest={useRequest}
      region={REGION}
      env="development"
    >
      {children}
    </FeatureToggleProvider>
  )
}

export default {
  Provider,
}
