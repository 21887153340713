import { useCallback as ReactUseCallback, useEffect as ReactUseEffect, useState as ReactUseState } from 'react'
import _ from 'underscore'

const RETRY_SOCKET_CONNECT_PERIOD = 5000 // ms

/**
 * @param {Object} o
 * @param {boolean} o.isAutoRetry
 * @param {string} o.sessionToken
 * @param {string} o.portal
 * @param {string} o.socketHost
 * @param {import('../../utils/env').Region} o.region
 * @param {Object} [o.customReactHooks] - Different react version might cause crashes, so we can pass some default React hook to override, like useState, useEffect,...
 * @returns {{isSocketReady: boolean, clearOnMessageHandlers: () => {}, addOnMessageHandler: () => {}, removeOnMessageHandler: () => {}}
 */

export const useWebSocketStore = ({ isAutoRetry = true, sessionToken, portal, socketHost, region, customReactHooks = {} }) => {
  const {
    useState = ReactUseState,
    useEffect = ReactUseEffect,
    useCallback = ReactUseCallback,
  } = customReactHooks

  /** @type {[WebSocket, import('react').Dispatch<WebSocket>]} */
  const [socket, setSocket] = useState(null)
  const [eventHandlers, setEventHandlers] = useState({})
  const [isSocketReady, setIsSocketReady] = useState(false)
  const openSocketConnect = useCallback(() => {
    try {
      return new WebSocket(socketHost)
    } catch (err) {
      console.error(err)
      return null
    }
  }, [portal, sessionToken])
  const initSocket = () => {
    const new_socket = openSocketConnect()
    setSocket(new_socket)
    if (new_socket) setIsSocketReady(true)
  }

  const clearOnMessageHandlers = () => {
    setEventHandlers({})
  }

  const handleOnMessage = () => {
    socket.onmessage = (message) => {
      // console.info('[websocket] message: ', message)
      try {
        const data = JSON.parse(message.data)
        if (data.name && eventHandlers[data.name]) {
          eventHandlers[data.name](data)
        }
      } catch (err) {
        console.info('socket message error', err)
      }
    }
  }

  const handleSocketOpen = () => {
    socket.onopen = () => {
      console.info('[websocket] connected')
      socket.send(JSON.stringify({
        token: `Basic ${sessionToken}`,
        events: Object.keys(eventHandlers),
        region,
        portal,
      }))
    }
  }

  const handleSocketClose = () => {
    socket.onclose = () => {
      console.info('[websocket] socket closed')
      setSocket(null)
    }
  }

  const handleSocketError = () => {
    socket.onerror = (err) => {
      console.error(err)
      if (isAutoRetry) {
        if (socket) socket.close()
        setTimeout(() => {
          initSocket()
        }, RETRY_SOCKET_CONNECT_PERIOD)
      }
    }
  }

  const closeSocketConnect = () => {
    if (socket) {
      clearOnMessageHandlers()
      socket.close()
    }
  }

  const addOnMessageHandler = (evtName, handler) => {
    setEventHandlers((previousHandlers) => ({
      ...previousHandlers,
      [evtName]: handler,
    }))
  }

  const removeOnMessageHandler = (evtName) => {
    setEventHandlers({
      ..._.omit(eventHandlers, evtName),
    })
  }

  useEffect(() => {
    initSocket()
    return () => {
      closeSocketConnect()
    }
  }, [])

  useEffect(() => {
    if (!socket) return
    handleSocketClose()
    handleSocketError()
  }, [socket])

  useEffect(() => {
    if (!socket || !sessionToken || Object.keys(eventHandlers).length === 0) return
    handleSocketOpen()
    handleOnMessage()
  }, [socket, sessionToken, eventHandlers])

  return {
    isSocketReady,
    clearOnMessageHandlers,
    addOnMessageHandler,
    removeOnMessageHandler,
  }
}
